var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"apply-for-mastercard-vue"},[_c('Header',{staticClass:"responsive-bar",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("apply-for-mastercard"))+" ")]},proxy:true}])}),_c('div',[(_vm.$store.getters.get_profile.state !== 'Full')?_c('div',{staticClass:"bg-white shadow rounded-md min-h-screen m-auto max-w-3xl sm:mt-5"},[_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"flex justify-between"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t("apply-for-mastercard"))+" ")])]),(
            _vm.$store.getters.get_profile.state !== 'Review' &&
            _vm.$store.getters.get_profile.state !== 'Pending'
          )?_c('p',{staticClass:"mt-1 max-w-2xl text-sm leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t("all-fields-below-are-mandatory"))+" ")]):_vm._e()]),(_vm.$store.getters.get_profile.state !== 'Full')?_c('complete-id-identification',{attrs:{"title":"before-you-are-able-to-apply-for-mastercard-you-must-complete-id-verification-process"}}):_vm._e()],1):_c('div',{staticClass:"bg-white shadow rounded-md min-h-screen m-auto max-w-3xl sm:mt-5"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.apply)}}},[_c('div',{staticClass:"pb-5"},[_c('div',{staticClass:"flex justify-between"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" "+_vm._s(_vm.$t("apply-for-mastercard"))+" ")])]),_c('p',{staticClass:"mt-1 max-w-2xl text-sm leading-5 text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t("all-fields-below-are-mandatory"))+" ")])]),_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{staticClass:"sm:grid sm:grid-cols-4",attrs:{"name":"full_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',{staticClass:"block text-sm font-normal leading-5 col-span-2",attrs:{"for":"full_name"}},[_vm._v(" "+_vm._s(_vm.$t("full-name-f11b368cddfe37c47af9b9d91c6ba4f0"))+" ")]),_c('div',{staticClass:"col-span-2"},[_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.full_name),expression:"form.full_name"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"full_name","placeholder":_vm.$t('full-name'),"type":"text"},domProps:{"value":(_vm.form.full_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "full_name", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"py-4 border-t"},[_c('div',{staticClass:"sm:grid sm:grid-cols-4"},[_c('div',{staticClass:"text-sm leading-5 col-span-2"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("mt4-account-number"))+" ")])]),(_vm.$store.getters.get_live_accounts.length === 0)?_c('need-trading-account-alert',{staticClass:"col-span-2",attrs:{"title":"You need to have a trading account in order to apply for mastercard."}}):_c('div',{staticClass:"col-span-2"},_vm._l((_vm.account_data),function(account,index){return _c('div',{key:index,staticClass:"border-l border-r border-b px-3 py-1 sm:grid sm:grid-cols-8 w-full h-18 cursor-pointer",class:index === 0 && _vm.account_data.length > 1
                            ? 'rounded-t-md border-t'
                            : '' ||
                              (index === _vm.account_data.length - 1 &&
                                _vm.account_data.length > 1)
                            ? 'rounded-b-md border-b'
                            : '' || _vm.account_data.length === 1
                            ? 'rounded-md border-t'
                            : '',on:{"click":function($event){return _vm.setAccount(account)}}},[_c('div',{staticClass:"col-span-1"},[(_vm.form.trading_account === account.id)?_c('svg',{attrs:{"fill":"none","height":"24","viewBox":"0 0 24 24","width":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"12","cy":"12","fill":"white","r":"6","stroke":"#00B8EE","stroke-width":"4"}})]):_c('svg',{attrs:{"fill":"none","height":"24","viewBox":"0 0 24 24","width":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('circle',{attrs:{"cx":"12","cy":"12","fill":"white","r":"7.5","stroke":"#D3D7DD"}})])]),(account.currency)?_c('div',{staticClass:"col-span-7"},[_c('div',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(account.backend.type)+" - "+_vm._s(account.external_id)+" ")]),_c('span',{staticClass:"text-xs font-light text-gray-400"},[_vm._v(" "+_vm._s(new Intl.NumberFormat("en-US", { style: "currency", currency: account.currency, }).format(account.balance.WithdrawableAmount))+" ")])]):_vm._e()])}),0)],1)]),_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{staticClass:"sm:grid sm:grid-cols-4",attrs:{"name":"Card currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('div',{staticClass:"text-sm leading-5 col-span-2"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("card-currency"))+" ")])]),_c('div',{staticClass:"col-span-2"},[_c('v-select',{staticClass:"style-chooser",attrs:{"clearable":false,"dir":_vm.checkLanguageDirection === 'rtl' ? 'rtl' : 'auto',"disabled":_vm.$store.getters.get_live_accounts.length === 0,"options":_vm.card_currency.choices,"placeholder":_vm.$t(
                            'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                          ),"reduce":function (item) { return item.value; },"label":"display_name"},model:{value:(_vm.form.card_currency),callback:function ($$v) {_vm.$set(_vm.form, "card_currency", $$v)},expression:"form.card_currency"}}),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{staticClass:"sm:grid sm:grid-cols-4",attrs:{"name":"addr_building","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"block text-sm font-normal leading-5 col-span-2",attrs:{"for":"full_name"}},[_vm._v(" "+_vm._s(_vm.$t("house-building-number"))+" ")]),_c('div',{staticClass:"col-span-2"},[_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.addr_building),expression:"form.addr_building"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"addr_building","disabled":_vm.$store.getters.get_live_accounts.length === 0,"placeholder":_vm.$t('house-building-number'),"type":"text"},domProps:{"value":(_vm.form.addr_building)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "addr_building", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{staticClass:"sm:grid sm:grid-cols-4",attrs:{"name":"addr_street","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{staticClass:"block text-sm font-normal leading-5 col-span-2",attrs:{"for":"full_name"}},[_vm._v(" "+_vm._s(_vm.$t("Street"))+" ")]),_c('div',{staticClass:"col-span-2"},[_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.addr_street),expression:"form.addr_street"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"addr_street","disabled":_vm.$store.getters.get_live_accounts.length === 0,"placeholder":_vm.$t('Street'),"type":"text"},domProps:{"value":(_vm.form.addr_street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "addr_street", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))]),(
                          _vm.form.addr_street.length > 0 &&
                          (_vm.whitelabel === 'ICMCapital' ||
                            _vm.whitelabel === 'ICMMENA')
                        )?_c('span',{staticClass:"text-sm text-gray-400 font-light h-4"},[_vm._v(" "+_vm._s(_vm.$t( "please-note-in-order-to-open-the-account-your-address-in-your-application-form-must-exactly-match-th" ))+" ")]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{staticClass:"sm:grid sm:grid-cols-4",attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"block text-sm font-normal leading-5 col-span-2",attrs:{"for":"full_name"}},[_vm._v(" "+_vm._s(_vm.$t("City"))+" ")]),_c('div',{staticClass:"col-span-2"},[_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.addr_city),expression:"form.addr_city"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"city","disabled":_vm.$store.getters.get_live_accounts.length === 0,"placeholder":_vm.$t('City'),"type":"text"},domProps:{"value":(_vm.form.addr_city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "addr_city", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{staticClass:"sm:grid sm:grid-cols-4",attrs:{"name":"post_code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"block text-sm font-normal leading-5 col-span-2",attrs:{"for":"full_name"}},[_vm._v(" "+_vm._s(_vm.$t("Postcode"))+" ")]),_c('div',{staticClass:"col-span-2"},[_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.addr_zip),expression:"form.addr_zip"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"post_code","disabled":_vm.$store.getters.get_live_accounts.length === 0,"placeholder":_vm.$t('Postcode'),"type":"text"},domProps:{"value":(_vm.form.addr_zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "addr_zip", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{staticClass:"sm:grid sm:grid-cols-4",attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"block text-sm font-normal leading-5 col-span-2",attrs:{"for":"country"}},[_vm._v(" "+_vm._s(_vm.$t("Country"))+" ")]),_c('div',{staticClass:"col-span-2"},[_c('div',{staticClass:"mt-1 rounded-md shadow-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.addr_country),expression:"form.addr_country"}],staticClass:"form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5",attrs:{"id":"country","disabled":_vm.$store.getters.get_live_accounts.length === 0,"placeholder":_vm.$t('Country'),"type":"text"},domProps:{"value":(_vm.form.addr_country)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "addr_country", $event.target.value)}}})]),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),(
                    _vm.whitelabel === 'ICMCapital' || _vm.whitelabel === 'ICMMENA'
                  )?_c('div',{staticClass:"py-4 border-t"},[_c('ValidationProvider',{attrs:{"name":"tos_accepted","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"relative flex items-start"},[_c('div',{staticClass:"flex items-center h-5"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tos_accepted),expression:"form.tos_accepted"}],staticClass:"form-checkbox h-4 w-4 transition duration-150 ease-in-out",attrs:{"id":"tos_accepted","disabled":_vm.$store.getters.get_live_accounts.length === 0,"required":true,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.tos_accepted)?_vm._i(_vm.form.tos_accepted,null)>-1:(_vm.form.tos_accepted)},on:{"change":function($event){var $$a=_vm.form.tos_accepted,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "tos_accepted", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "tos_accepted", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "tos_accepted", $$c)}}}})]),_c('div',{staticClass:"ms-3 text-sm leading-5 html-a"},[_c('label',{staticClass:"font-normal",attrs:{"for":"tos_accepted"},on:{"input":function($event){$event, _vm.form.tos_accepted}}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.agree)}})])])]),_c('span',{staticClass:"text-sm text-red-600 h-4"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()])]),_c('div',{staticClass:"w-full mt-5"},[_c('span',{staticClass:"inline-flex w-full rounded-md shadow-sm"},[_c('Button',{staticClass:"relative w-full block bg-main-button-color items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white focus:outline-none focus:shadow-outline-indigo",attrs:{"disabled":_vm.isDisabled ||
                    _vm.$store.getters.get_live_accounts.length === 0,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("Submit"))+" ")])],1)])])])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }